import { graphql, useStaticQuery } from "gatsby";

const UseGetHome = () => {
  const data = useStaticQuery(graphql`
  query GetHome {
    ar : wpPage(databaseId: {eq: 119}) {
      home {
        headButton
        headText
        callToActions {
          ctaOneAction
          ctaOneHeader
          ctaOneText
          ctaThreeAction
          ctaThreeHeader
          ctaThreeText
          ctaTwoAction
          ctaTwoHeader
          ctaTwoText
        }
        impacts {
          impactOneAction
          impactOneText
          impactThreeAction
          impactThreeText
          impactTwoAction
          impactTwoText
        }
      }
    }
      en : wpPage(databaseId: {eq: 98}) {
      home {
        headButton
        headText
        callToActions {
          ctaOneAction
          ctaOneHeader
          ctaOneText
          ctaThreeAction
          ctaThreeHeader
          ctaThreeText
          ctaTwoAction
          ctaTwoHeader
          ctaTwoText
        }
        impacts {
          impactOneAction
          impactOneText
          impactThreeAction
          impactThreeText
          impactTwoAction
          impactTwoText
        }
      }
    }
  }
  `);
  let homeEn = data?.en?.home;
  let homeAr = data?.ar?.home;
  return { homeEn, homeAr };
};

export default UseGetHome;
