import styled from "styled-components";
import bg from "../../../assets/images/home/impactBG.png";
export const ImpactWrapper = styled.div`
  background: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8% 0;
  .impact-container {
    max-width: 70%;
    margin: 0 auto;
    h3 {
      font-weight: 800;
      color: #221f20;
      text-align: center;
      margin-bottom: 25px;
    }
    p {
      text-align: center;
      margin-bottom: 25px;
    }
  }
  .impacts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    padding: 5% 0;
    .impact-container {
      text-align: center;
      h3 {
        font-size: 2rem;
        margin-bottom: 15px;
      }
      p{
        font-size: 1.2rem;
        margin-bottom: 50px;
      }
    }
    .impacts {
      flex-direction: row;
      justify-content: space-around;
    }
  }
`;

export const SingleImpact = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 6px;
  background-color: white;
  padding: 8px 15px;
  margin-bottom: 20px;
  border-radius: 20px;
  span {
    font-size: 10vw;
  }
  h6 {
    text-align: center;
    margin: 0;
    color: #4bb659;
    font-weight: 800;

    p {
      margin-top: 5px;
      color: #221f20;
      font-weight: normal;
    }
  }
  @media screen and (min-width: 992px) {
    padding: 5px 10px;
    width: 25%;
    span{
      font-size: 5rem;
      font-weight: 600;
    }
    h6{
      font-size: 1.2rem;
    }
    p{
      font-size: 1rem;
    }
  }
`;
