import React from "react";
import { BiArrowToBottom } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import { TbHelmet } from "react-icons/tb";
import { ImpactWrapper, SingleImpact } from "./impact.styled";
import parse from "html-react-parser";
import { useLanguage } from "../../../layout/layout";

const Impact = ({ impacts }: any) => {
  const lang = useLanguage();
  return (
    <ImpactWrapper>
      <div className="impact-container">
        <h3>{lang == "en" ? "OUR IMPACT" : "تاثيرنا"}</h3>
        <p>
          {lang == "en"
            ? "Our presence is making a difference in the way food is delivered from our real Cashback reward program to our exquisite hygiene measures."
            : "وجودنا يغير من طريقة توصيل الطعام إلى بيوتكم فبرنامجنا يكافي بنظام الكاش باك و يضمن نضافة وجودة الخدمات "}
        </p>

        <div className="impacts">
          <SingleImpact>
            <span>
              <BiArrowToBottom />
            </span>
            <h6>
              {impacts.impactOneAction}
              <p>{parse(impacts.impactOneText)}</p>
            </h6>
          </SingleImpact>
          <SingleImpact>
            <span>
              <TbHelmet />
            </span>
            <h6>
              {impacts.impactTwoAction}
              <p>{parse(impacts.impactTwoText)}</p>
            </h6>
          </SingleImpact>
          <SingleImpact>
            <span>
              <BsCartCheck />
            </span>
            <h6>
              {impacts.impactThreeAction}
              <p>{parse(impacts.impactThreeText)}</p>
            </h6>
          </SingleImpact>
        </div>
      </div>
    </ImpactWrapper>
  );
};

export default Impact;
