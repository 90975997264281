import React from "react";
import { CTAWarapper } from "./cta.styled";
import { SingleCTAWrapper } from "./cta.styled";
import CTAIMG from "../../../assets/images/home/cta1.png";
import CTAIMG2 from "../../../assets/images/home/cta2.png";
import CTAIMG3 from "../../../assets/images/home/cta3.png";
import { FaArrowRight } from "react-icons/fa";
import parse from "html-react-parser";
import {Link} from "gatsby"
const CTA = ({ cta }: any) => {
  return (
    <CTAWarapper>
      <SingleCTAWrapper img={CTAIMG}>
        <div className="img"></div>
        <div className="content">
          <h4>{cta.ctaOneHeader}</h4>
          <p>{parse(cta.ctaOneText)}</p>
          <h6>
          <Link href="https://download.redrush.co">{cta.ctaOneAction} <span>{<FaArrowRight />}</span>
                </Link>
          </h6>
        </div>
      </SingleCTAWrapper>
      <SingleCTAWrapper img={CTAIMG2}>
        <div className="img"></div>
        <div className="content">
          <h4>{cta.ctaTwoHeader}</h4>
          <p>{parse(cta.ctaTwoText)}</p>
          <h6>
          <Link href="/partner">{cta.ctaTwoAction} <span>{<FaArrowRight />}</span>
          </Link>
          </h6>
        </div>
      </SingleCTAWrapper>
      <SingleCTAWrapper img={CTAIMG3}>
        <div className="img"></div>
        <div className="content">
          <h4>{cta.ctaThreeHeader}</h4>
          <p>{parse(cta.ctaThreeText)}</p>
          <h6>
          <Link href="https://play.google.com/store/apps/details?id=co.redrush.driver">{cta.ctaThreeAction} <span>{<FaArrowRight />}</span>
                </Link>
          </h6>
        </div>
      </SingleCTAWrapper>
    </CTAWarapper>
  );
};

export default CTA;
