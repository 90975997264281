import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import Home from "../components/home/home";

const IndexPage: React.FC<PageProps> = () => {
  return (
    <>
      <Layout>
        <Home />
      </Layout>
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Rush App</title>;
