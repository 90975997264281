import styled from "styled-components";

interface Props {
  img: string;
}

export const CTAWarapper = styled.div`
  height: 100%;
  width: 80%;
  margin: 70px auto;
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
`;

export const SingleCTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
  height: 100%;
  align-items: stretch;
  gap: 2rem;
  .img {
    height: 8rem;
    width: 38%;
    background: url(${(props: Props) => props.img || ""});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    line-height: 1.2rem;
    a{
      text-decoration: none;
      color: #3eb14d;
    }
    p {
      white-space: pre-line;
      font-size: 1rem;
    }
    h4 {
      font-weight: 800;
      font-size: 1.6rem;
    }
    h6 {
      color: #3eb14d;
      font-weight: 700;
      font-size: 1rem;
      direction: ltr;
      span {
        margin-left: 5px;
      }
    }
  }
  @media screen and (min-width: 600px) {

  }
  @media screen and (min-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .content {
      
      h4 {
        font-size: 1.4rem;
        margin: 10px auto;
      }
      h6 {
        font-size: 1rem;
        margin: 10px auto;
      }
      p{
        white-space: normal;
        font-size: 1rem;
        margin: 10px auto;
        height: 80px;
      }
    }
  }
`;
