import React, { useEffect, useState } from "react";
import UseGetHome from "../../hooks/useGetHome";
import { useLanguage } from "../layout/layout";
import CTA from "./parts/CTA/cta";
import Head from "./parts/header/head";
import Impact from "./parts/impact/impact";

const Home = () => {
  const [home, setHome] = useState<any>();
  const { homeEn, homeAr } = UseGetHome();
  const lang = useLanguage();

  useEffect(() => {
    lang == "en" ? setHome(homeEn) : setHome(homeAr);
  }, [lang]);

  return (
    <div>
      {home && (
        <>
          <Head text={home.headText} button={home.headButton} />
          <CTA cta={home.callToActions} />
          <Impact impacts={home.impacts} />
        </>
      )}
    </div>
  );
};

export default Home;
