import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Button } from "react-bootstrap";
import { HeadWrapper } from "./head.styled";
import parse from "html-react-parser";
type headProps = {
  text: string;
  button : string;
};
const Head = ({ text,button }: headProps) => {
  return (
    <HeadWrapper>
      <h1>{parse(text)}</h1>
      <Button className="btn-dark getApp" href="https://download.redrush.co">{button}</Button>
      <div className="orange d-none d-lg-block"></div>
      <div className="yogurt d-none d-lg-block"></div>
      <div className="salad d-none d-lg-block"></div>
      <div className="tomato d-none d-lg-block"></div>
      <div className="burger d-none d-lg-block"></div>
    </HeadWrapper>
  );
};

export default Head;
