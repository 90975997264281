import styled from "styled-components";
import head from "../../../assets/images/home/header.png";
import headSm from "../../../assets/images/home/header-sm.png";
import orange from "../../../assets/images/home/orange.png";
import salad from "../../../assets/images/home/salad.png";
import tomato from "../../../assets/images/home/tomato.png";
import burger from "../../../assets/images/home/burger.png";
import yogurt from "../../../assets/images/home/yogurt.png";
export const HeadWrapper = styled.div`
  background: url(${headSm});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  gap: 1rem;

  h1 {
    color: white;
    text-align: center;
  }
  .orange {
    display: block;
    width: 150px;
    height: 130px;
    background: url(${orange});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 20%;
    right: 10%;
    z-index: 1;
  }
  .burger {
    display: block;
    width: 220px;
    height: 200px;
    background: url(${burger});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
  .salad {
    display: block;
    width: 220px;
    height: 300px;
    background: url(${salad});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -4vh;
    left: 0;
    z-index: 2;
  }
  .tomato {
    display: block;
    width: 150px;
    height: 130px;
    background: url(${tomato});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 10%;
    right: 20%;
    z-index: 2;
  }
  .yogurt {
    display: block;
    width: 150px;
    height: 130px;
    background: url(${yogurt});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 10%;
    left: 5%;
    z-index: 2;
  }

  @media screen and (min-width: 992px) {
    background: url(${head});
    min-height: 70vh;
    position: relative;
    width: 100%;
    background-size: cover;
    padding-top: 5%;
    justify-content: space-around;
    .getApp {
      transform: scale(1.4) translateY(-50px)

    }
    .salad{
      bottom: -70px;
      width: 280px;
    height: 330px;
    }
  }
`;
